import { CookieConfig, CookieSettingsItem } from "./types";

export const COOKIE_NAME = "_ux_cc";
export const COOKIE_DOMAIN_TRUMPF_COM = ".trumpf.com";
export const COOKIE_DOMAIN_TRUMPF_CN = ".trumpf.cn"; // China
export const COOKIE_EXPIRES = 365;

export const cookiePrefOptions: CookieSettingsItem[] = [
    {
        key: "required",
        required: true,
        i18nLabel: "requiredCookiesLabel",
        i18nDesc: "requiredCookiesDesc",
    },
    {
        key: "optional",
        required: false,
        i18nLabel: "optionalCookiesLabel",
        i18nDesc: "optionalCookiesDesc",
    },
];

const getCookieDomain = (): string | undefined => {
    if (
        window.location.hostname.slice(-COOKIE_DOMAIN_TRUMPF_COM.length) ===
        COOKIE_DOMAIN_TRUMPF_COM
    ) {
        return COOKIE_DOMAIN_TRUMPF_COM;
    }
    if (
        window.location.hostname.slice(-COOKIE_DOMAIN_TRUMPF_CN.length) ===
        COOKIE_DOMAIN_TRUMPF_CN
    ) {
        return COOKIE_DOMAIN_TRUMPF_CN;
    }
    return undefined;
};

export const getConfig = (): CookieConfig => {
    return {
        cookieName: COOKIE_NAME,
        expires: COOKIE_EXPIRES,
        secure: false,
        sameSite: "lax",
        domain: getCookieDomain(),
    };
};
