import { cookieConsentController } from "./cookieConsentController";

let isInitialized: boolean = false;
let isInitializedCookieLayer: boolean = false;
let initializerTimeout: number;

const initCookieLayer = () => {
    if (isInitializedCookieLayer) {
        return;
    }
    isInitializedCookieLayer = true;
    window.clearTimeout(initializerTimeout);
    window.removeEventListener("DOMContentLoaded", initCookieLayer);
    cookieConsentController.init();
};

export const cookieConsentInitializer = (): void => {
    if (!isInitialized) {
        isInitialized = true;
        window.addEventListener("DOMContentLoaded", initCookieLayer);
        initializerTimeout = window.setTimeout(initCookieLayer, 5000);
    }
};

export default cookieConsentInitializer;
