import { cookieConsentClient } from "./cookieConsentClient";
import { cookieConsentInitializer } from "./cookieConsentInitializer";
import { cookieConsentController } from "./cookieConsentController";

export * from "./config";
export * from "./types";
export {
    cookieConsentClient,
    cookieConsentController,
    cookieConsentInitializer,
};

export default {
    ...cookieConsentClient,
    ...cookieConsentController,
};
