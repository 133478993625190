import { CookieLayerViews } from "../components/cookie-layer/types";
import { CookieConsentInitOptions, CookieConsentController } from "./types";
import { trackCookieLayerShow, trackCookieSettings } from "./tracking";

const getCookieLayer = (): HTMLUxCookieLayerElement | null => {
    return document.querySelector(
        "ux-cookie-layer"
    ) as HTMLUxCookieLayerElement;
};

const configureCookieLayer = (
    cookieLayer: HTMLUxCookieLayerElement,
    config: CookieConsentInitOptions
): void => {
    if (config.locale !== undefined) {
        cookieLayer.locale = config.locale;
    }
    if (config.theme !== undefined) {
        cookieLayer.theme = config.theme;
    }
};

const init = (initOptions?: CookieConsentInitOptions): void => {
    const config: CookieConsentInitOptions = {
        ...initOptions,
    };
    let cookieLayer: HTMLUxCookieLayerElement | null = getCookieLayer();
    if (cookieLayer !== null) {
        configureCookieLayer(cookieLayer, config);
        return;
    }
    cookieLayer = document.createElement("ux-cookie-layer");
    configureCookieLayer(cookieLayer, {
        locale: document.documentElement.lang,
        ...config,
    });
    cookieLayer.addEventListener("uxCookieSettings", trackCookieSettings);
    cookieLayer.addEventListener("uxCookieLayerShow", trackCookieLayerShow);
    document.body.appendChild(cookieLayer);
};

const showInfo = async (): Promise<void> => {
    const cookieLayer = getCookieLayer();
    if (cookieLayer !== null) {
        await cookieLayer.show(CookieLayerViews.INFO);
    }
};

const showSettings = async (): Promise<void> => {
    const cookieLayer = getCookieLayer();
    if (cookieLayer !== null) {
        await cookieLayer.show(CookieLayerViews.SETTINGS);
    }
};

export const cookieConsentController: CookieConsentController = {
    init,
    getCookieLayer,
    showInfo,
    showSettings,
};

export default cookieConsentController;
