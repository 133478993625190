export const registerGlobal = (
    obj: any,
    namespace: string[],
    globalObj?: any
): void => {
    let targetObj: any = globalObj || window;
    namespace.forEach((part, index) => {
        if (index === namespace.length - 1) {
            targetObj[part] = obj;
        } else {
            targetObj[part] = targetObj[part] || {};
            targetObj = targetObj[part];
        }
    });
};

export default registerGlobal;
