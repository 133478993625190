export enum CookieLayerViews {
    INFO = "info",
    SETTINGS = "settings",
}

export enum CookieLayerTriggers {
    INFO = "info",
    SETTINGS = "settings",
    ACCEPT_REQUIRED = "accept-required",
    ACCEPT_ALL = "accept-all",
}

export type CookieLayerViewType = "info" | "settings";

export interface CookieLayerViewAction {
    open: boolean;
    view: CookieLayerViewType;
}
